export default class HttpHeader {
  public static decode(encodedValue: string | null): string {
    const utf8Prefix = "utf-8''";

    if (!encodedValue) return "";

    if (encodedValue.startsWith(utf8Prefix)) {
      encodedValue = encodedValue.substring(utf8Prefix.length);
      return decodeURIComponent(encodedValue);
    }

    return encodedValue;
  }
}
