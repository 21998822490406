import $$ from "../utils/double-dollar.ts";

export class QuoteFileUpload {
  private readonly file: HTMLInputElement;
  private readonly text: HTMLInputElement;
  private readonly btn: HTMLButtonElement;
  private readonly action: HTMLInputElement;

  constructor(private el: HTMLElement) {
    this.file = this.el.querySelector(".js-quote-file-upload--input-file") as HTMLInputElement;
    this.text = this.el.querySelector(".js-quote-file-upload--input-text") as HTMLInputElement;
    this.btn = this.el.querySelector(".js-quote-file-upload--button") as HTMLButtonElement;
    this.action = this.el.querySelector("#Action") as HTMLInputElement;

    this.text.addEventListener("click", this.openFilePicker.bind(this));
    if (this.btn.type === "button") {
      this.btn.disabled = false;
      this.btn.addEventListener("click", this.openFilePicker.bind(this));
    }
    this.file.addEventListener("change", this.onChangeFile.bind(this));
  }

  openFilePicker() {
    this.file.click();
  }

  private onChangeFile(): void {
    const fileName = this.file.value.replace(/^.*[\\\/]/, "") ?? "";
    this.text.value = fileName;
    if (this.file.files == null || this.file.files.length == 0) return;
    const file = this.file?.files[0];
    if (file != null) {
      this.upload(this.action.value, fileName, file);
    }
    if (this.btn.type === "submit") {
      this.btn.disabled = fileName === null || /^\s*$/.test(fileName);
    }
  }

  private upload = (url: string = "", fileName: string, file: File): void => {
    if (!file) {
      return;
    }
    const uniqueId = this.file.dataset.uniqueId ?? "";
    const formData = new FormData();
    formData.append("attachment", file);
    formData.append("uniqueId", uniqueId);

    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          console.log("error uploading attachment on api " + url);
        }
        return response.json();
      })
      .then(() => {
        const attachmentElement = document.querySelector(
          "[data-attachment-uniqueId = '" + uniqueId + "']",
        ) as HTMLSpanElement;
        if (attachmentElement != null) {
          attachmentElement.innerText = fileName;
          attachmentElement?.classList.remove("d-none");
          attachmentElement?.nextElementSibling?.classList.remove("d-none");
        }
        // remove content to allow uploading same attachment
        this.file.value = "";
      })
      .catch((error) => {
        console.log("Error occurred while uploading attachment." + error);
      });
  };
}

$$(".js-quote-file-upload", (el) => new QuoteFileUpload(el));
