import NatchGtm, { NatchOsDataLayerProduct } from "natch-gtm4";
import HttpHeader from "./HttpHeader.ts";

export default class GtmEvents {
  public static processFromHttpHeaders(xhr: XMLHttpRequest, natchGtm: NatchGtm) {
    const decode = HttpHeader.decode;

    // AddToFavorites GTM
    const addToFavorites = decode(xhr.getResponseHeader("X-AddToFavorites-Product"));
    if (addToFavorites) {
      const addToCartLineAmount = parseFloat(decode(xhr.getResponseHeader("X-AddToFavorites-LineAmount")));
      natchGtm.trackAddToFavorites(JSON.parse(addToFavorites), addToCartLineAmount);
    }

    // AddToCart GTM
    const addToCartProduct = decode(xhr.getResponseHeader("X-AddToCart-Product"));
    if (addToCartProduct) {
      const addToCartLineAmount = parseFloat(decode(xhr.getResponseHeader("X-AddToCart-LineAmount")));
      natchGtm.trackAddToCart(JSON.parse(addToCartProduct), addToCartLineAmount);
    }

    // RemoveFromCart GTM
    const removeFromCartProduct = decode(xhr.getResponseHeader("X-RemoveFromCart-Product"));
    if (removeFromCartProduct) {
      const removeFromCartLineAmount = parseFloat(decode(xhr.getResponseHeader("X-RemoveFromCart-LineAmount")));
      natchGtm.trackRemoveFromCart(JSON.parse(removeFromCartProduct), removeFromCartLineAmount);
    }

    // multiple AddToCart GTM
    const addToCartProducts = decode(xhr.getResponseHeader("X-AddToCart-Products"));
    if (addToCartProducts) {
      const data: { product: NatchOsDataLayerProduct; lineAmount: number }[] = JSON.parse(addToCartProducts);
      for (const item of data) {
        natchGtm.trackAddToCart(item.product, item.lineAmount);
      }
    }
  }
}
