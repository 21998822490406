import $$ from "../utils/double-dollar.ts";
import Submit from "./submit.ts";

export default class InputDate {
  private readonly input: HTMLInputElement;
  private readonly changeEventHandler: () => void;
  private readonly submitOnChange: boolean;

  constructor(element: HTMLElement) {
    this.input = element as HTMLInputElement;
    this.submitOnChange = this.input.classList.contains("js-submit-on-change");

    this.changeEventHandler = this.submit.bind(this);
    this.input.addEventListener("change", this.changeEventHandler);
    this.input.addEventListener("keypress", this.handleKeypress.bind(this));
    this.input.addEventListener("click", this.handleClick.bind(this));
  }

  private submit() {
    this.input.dispatchEvent(new Event("datepicker:date-changed"));
    if (this.submitOnChange) Submit.trySubmit(this.input);
  }

  private handleKeypress(e: KeyboardEvent) {
    this.input.removeEventListener("change", this.changeEventHandler);
    this.input.removeEventListener("blur", this.changeEventHandler);
    this.input.addEventListener("blur", this.changeEventHandler);

    if (e.key === "Enter") {
      this.submit();
    }
  }

  private handleClick() {
    // ensure that if you type a date, then click on calendar, the date is submitted
    // if we do not trigger blur and the user selects the same date as in the input box,
    // the calendar closes but the input field does not fire a change event
    this.input.dispatchEvent(new Event("blur"));

    this.input.removeEventListener("change", this.changeEventHandler);
    this.input.addEventListener("change", this.changeEventHandler);
  }

  static init() {
    $$("input[type='date']", (el) => new InputDate(el));
  }
}

InputDate.init();
